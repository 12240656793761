*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: Inter var, ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden]:where(:not([hidden="until-found"])) {
  display: none;
}

[type="text"], input:where(:not([type])), [type="email"], [type="url"], [type="password"], [type="number"], [type="date"], [type="datetime-local"], [type="month"], [type="search"], [type="tel"], [type="time"], [type="week"], [multiple], textarea, select {
  appearance: none;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="text"]:focus, input:where(:not([type])):focus, [type="email"]:focus, [type="url"]:focus, [type="password"]:focus, [type="number"]:focus, [type="date"]:focus, [type="datetime-local"]:focus, [type="month"]:focus, [type="search"]:focus, [type="tel"]:focus, [type="time"]:focus, [type="week"]:focus, [multiple]:focus, textarea:focus, select:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb;
  outline: 2px solid #0000;
}

input::placeholder, textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-date-and-time-value {
  min-height: 1.5em;
  text-align: inherit;
}

::-webkit-datetime-edit {
  display: inline-flex;
}

::-webkit-datetime-edit {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-year-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-month-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-day-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-hour-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-minute-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-second-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-millisecond-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-meridiem-field {
  padding-top: 0;
  padding-bottom: 0;
}

select {
  print-color-adjust: exact;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right .5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

[multiple], [size]:where(select:not([size="1"])) {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  print-color-adjust: unset;
  padding-right: .75rem;
}

[type="checkbox"], [type="radio"] {
  appearance: none;
  print-color-adjust: exact;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  color: #2563eb;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  background-origin: border-box;
  border-width: 1px;
  border-color: #6b7280;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  padding: 0;
  display: inline-block;
}

[type="checkbox"] {
  border-radius: 0;
}

[type="radio"] {
  border-radius: 100%;
}

[type="checkbox"]:focus, [type="radio"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  outline: 2px solid #0000;
}

[type="checkbox"]:checked, [type="radio"]:checked {
  background-color: currentColor;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

@media (forced-colors: active) {
  [type="checkbox"]:checked {
    appearance: auto;
  }
}

[type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

@media (forced-colors: active) {
  [type="radio"]:checked {
    appearance: auto;
  }
}

[type="checkbox"]:checked:hover, [type="checkbox"]:checked:focus, [type="radio"]:checked:hover, [type="radio"]:checked:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="checkbox"]:indeterminate {
  background-color: currentColor;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

@media (forced-colors: active) {
  [type="checkbox"]:indeterminate {
    appearance: auto;
  }
}

[type="checkbox"]:indeterminate:hover, [type="checkbox"]:indeterminate:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="file"] {
  background: unset;
  border-color: inherit;
  font-size: unset;
  line-height: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

[type="file"]:focus {
  outline: 1px solid buttontext;
  outline: 1px auto -webkit-focus-ring-color;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.left-1\/2 {
  left: 50%;
}

.top-0 {
  top: 0;
}

.top-1\/2 {
  top: 50%;
}

.isolate {
  isolation: isolate;
}

.z-10 {
  z-index: 10;
}

.z-50 {
  z-index: 50;
}

.row-start-2 {
  grid-row-start: 2;
}

.-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.-my-2 {
  margin-top: -.5rem;
  margin-bottom: -.5rem;
}

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.flow-root {
  display: flow-root;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.size-\[max\(100\%\,2\.75rem\)\] {
  width: max(100%, 2.75rem);
  height: max(100%, 2.75rem);
}

.h-20 {
  height: 5rem;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-full {
  height: 100%;
}

.max-h-full {
  max-height: 100%;
}

.min-h-full {
  min-height: 100%;
}

.w-0 {
  width: 0;
}

.w-4 {
  width: 1rem;
}

.w-5 {
  width: 1.25rem;
}

.w-auto {
  width: auto;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.min-w-0 {
  min-width: 0;
}

.min-w-full {
  min-width: 100%;
}

.max-w-full {
  max-width: 100%;
}

.max-w-sm {
  max-width: 24rem;
}

.flex-1 {
  flex: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.border-separate {
  border-collapse: separate;
}

.border-spacing-0 {
  --tw-border-spacing-x: 0px;
  --tw-border-spacing-y: 0px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-12 {
  --tw-translate-y: 3rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.list-disc {
  list-style-type: disc;
}

.appearance-none {
  appearance: none;
}

.grid-rows-\[1fr_auto\] {
  grid-template-rows: 1fr auto;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-items-center {
  justify-items: center;
}

.gap-3 {
  gap: .75rem;
}

.gap-x-2 {
  column-gap: .5rem;
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.overflow-y-auto {
  overflow-y: auto;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.text-balance {
  text-wrap: balance;
}

.text-pretty {
  text-wrap: pretty;
}

.rounded {
  border-radius: .25rem;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-t-3xl {
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-b {
  border-bottom-width: 1px;
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
}

.border-slate-200 {
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity, 1));
}

.border-slate-300 {
  --tw-border-opacity: 1;
  border-color: rgb(203 213 225 / var(--tw-border-opacity, 1));
}

.border-transparent {
  border-color: #0000;
}

.border-zinc-950\/10 {
  border-color: #09090b1a;
}

.bg-\[--btn-border\] {
  background-color: var(--btn-border);
}

.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
}

.bg-red-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 242 242 / var(--tw-bg-opacity, 1));
}

.bg-sky-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 122 164 / var(--tw-bg-opacity, 1));
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.bg-zinc-950\/25 {
  background-color: #09090b40;
}

.bg-zinc-950\/\[2\.5\%\] {
  background-color: #09090b06;
}

.bg-opacity-75 {
  --tw-bg-opacity: .75;
}

.object-cover {
  object-fit: cover;
}

.p-1 {
  padding: .25rem;
}

.p-4 {
  padding: 1rem;
}

.p-\[--gutter\] {
  padding: var(--gutter);
}

.px-0\.5 {
  padding-left: .125rem;
  padding-right: .125rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-\[calc\(theme\(spacing\[3\.5\]\)-1px\)\] {
  padding-left: calc(.875rem - 1px);
  padding-right: calc(.875rem - 1px);
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-3\.5 {
  padding-top: .875rem;
  padding-bottom: .875rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-\[calc\(theme\(spacing\[2\.5\]\)-1px\)\] {
  padding-top: calc(.625rem - 1px);
  padding-bottom: calc(.625rem - 1px);
}

.pl-4 {
  padding-left: 1rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.text-left {
  text-align: left;
}

.align-middle {
  vertical-align: middle;
}

.font-sans {
  font-family: Inter var, ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-base\/6 {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-lg\/6 {
  font-size: 1.125rem;
  line-height: 1.5rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.capitalize {
  text-transform: capitalize;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-9 {
  line-height: 2.25rem;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.text-amber-950 {
  --tw-text-opacity: 1;
  color: rgb(69 26 3 / var(--tw-text-opacity, 1));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity, 1));
}

.text-cyan-950 {
  --tw-text-opacity: 1;
  color: rgb(8 51 68 / var(--tw-text-opacity, 1));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity, 1));
}

.text-lime-950 {
  --tw-text-opacity: 1;
  color: rgb(26 46 5 / var(--tw-text-opacity, 1));
}

.text-red-400 {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity, 1));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity, 1));
}

.text-red-700 {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity, 1));
}

.text-red-800 {
  --tw-text-opacity: 1;
  color: rgb(153 27 27 / var(--tw-text-opacity, 1));
}

.text-sky-600 {
  --tw-text-opacity: 1;
  color: rgb(37 122 164 / var(--tw-text-opacity, 1));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.text-yellow-500 {
  --tw-text-opacity: 1;
  color: rgb(234 179 8 / var(--tw-text-opacity, 1));
}

.text-yellow-950 {
  --tw-text-opacity: 1;
  color: rgb(66 32 6 / var(--tw-text-opacity, 1));
}

.text-zinc-500 {
  --tw-text-opacity: 1;
  color: rgb(113 113 122 / var(--tw-text-opacity, 1));
}

.text-zinc-950 {
  --tw-text-opacity: 1;
  color: rgb(9 9 11 / var(--tw-text-opacity, 1));
}

.underline {
  text-decoration-line: underline;
}

.decoration-zinc-950\/50 {
  text-decoration-color: #09090b80;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline {
  outline-style: solid;
}

.ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-inset {
  --tw-ring-inset: inset;
}

.ring-gray-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity, 1));
}

.ring-zinc-950\/10 {
  --tw-ring-color: #09090b1a;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur {
  --tw-backdrop-blur: blur(8px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.backdrop-filter {
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.duration-100 {
  transition-duration: .1s;
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.\[--btn-bg\:theme\(colors\.amber\.400\)\] {
  --btn-bg: #fbbf24;
}

.\[--btn-bg\:theme\(colors\.blue\.600\)\] {
  --btn-bg: #2563eb;
}

.\[--btn-bg\:theme\(colors\.cyan\.300\)\] {
  --btn-bg: #67e8f9;
}

.\[--btn-bg\:theme\(colors\.emerald\.600\)\] {
  --btn-bg: #059669;
}

.\[--btn-bg\:theme\(colors\.fuchsia\.500\)\] {
  --btn-bg: #d946ef;
}

.\[--btn-bg\:theme\(colors\.green\.600\)\] {
  --btn-bg: #16a34a;
}

.\[--btn-bg\:theme\(colors\.indigo\.500\)\] {
  --btn-bg: #6366f1;
}

.\[--btn-bg\:theme\(colors\.lime\.300\)\] {
  --btn-bg: #bef264;
}

.\[--btn-bg\:theme\(colors\.orange\.500\)\] {
  --btn-bg: #f97316;
}

.\[--btn-bg\:theme\(colors\.pink\.500\)\] {
  --btn-bg: #ec4899;
}

.\[--btn-bg\:theme\(colors\.purple\.500\)\] {
  --btn-bg: #a855f7;
}

.\[--btn-bg\:theme\(colors\.red\.600\)\] {
  --btn-bg: #dc2626;
}

.\[--btn-bg\:theme\(colors\.rose\.500\)\] {
  --btn-bg: #f43f5e;
}

.\[--btn-bg\:theme\(colors\.sky\.500\)\] {
  --btn-bg: #3597c2;
}

.\[--btn-bg\:theme\(colors\.teal\.600\)\] {
  --btn-bg: #0d9488;
}

.\[--btn-bg\:theme\(colors\.violet\.500\)\] {
  --btn-bg: #8b5cf6;
}

.\[--btn-bg\:theme\(colors\.yellow\.300\)\] {
  --btn-bg: #fde047;
}

.\[--btn-bg\:theme\(colors\.zinc\.600\)\] {
  --btn-bg: #52525b;
}

.\[--btn-bg\:theme\(colors\.zinc\.900\)\] {
  --btn-bg: #18181b;
}

.\[--btn-bg\:white\] {
  --btn-bg: white;
}

.\[--btn-border\:theme\(colors\.amber\.500\/80\%\)\] {
  --btn-border: #f59e0bcc;
}

.\[--btn-border\:theme\(colors\.blue\.700\/90\%\)\] {
  --btn-border: #1d4ed8e6;
}

.\[--btn-border\:theme\(colors\.cyan\.400\/80\%\)\] {
  --btn-border: #22d3eecc;
}

.\[--btn-border\:theme\(colors\.emerald\.700\/90\%\)\] {
  --btn-border: #047857e6;
}

.\[--btn-border\:theme\(colors\.fuchsia\.600\/90\%\)\] {
  --btn-border: #c026d3e6;
}

.\[--btn-border\:theme\(colors\.green\.700\/90\%\)\] {
  --btn-border: #15803de6;
}

.\[--btn-border\:theme\(colors\.indigo\.600\/90\%\)\] {
  --btn-border: #4f46e5e6;
}

.\[--btn-border\:theme\(colors\.lime\.400\/80\%\)\] {
  --btn-border: #a3e635cc;
}

.\[--btn-border\:theme\(colors\.orange\.600\/90\%\)\] {
  --btn-border: #ea580ce6;
}

.\[--btn-border\:theme\(colors\.pink\.600\/90\%\)\] {
  --btn-border: #db2777e6;
}

.\[--btn-border\:theme\(colors\.purple\.600\/90\%\)\] {
  --btn-border: #9333eae6;
}

.\[--btn-border\:theme\(colors\.red\.700\/90\%\)\] {
  --btn-border: #b91c1ce6;
}

.\[--btn-border\:theme\(colors\.rose\.600\/90\%\)\] {
  --btn-border: #e11d48e6;
}

.\[--btn-border\:theme\(colors\.sky\.600\/80\%\)\] {
  --btn-border: #257aa4cc;
}

.\[--btn-border\:theme\(colors\.teal\.700\/90\%\)\] {
  --btn-border: #0f766ee6;
}

.\[--btn-border\:theme\(colors\.violet\.600\/90\%\)\] {
  --btn-border: #7c3aede6;
}

.\[--btn-border\:theme\(colors\.yellow\.400\/80\%\)\] {
  --btn-border: #facc15cc;
}

.\[--btn-border\:theme\(colors\.zinc\.700\/90\%\)\] {
  --btn-border: #3f3f46e6;
}

.\[--btn-border\:theme\(colors\.zinc\.950\/10\%\)\] {
  --btn-border: #09090b1a;
}

.\[--btn-border\:theme\(colors\.zinc\.950\/90\%\)\] {
  --btn-border: #09090be6;
}

.\[--btn-hover-overlay\:theme\(colors\.white\/10\%\)\] {
  --btn-hover-overlay: #ffffff1a;
}

.\[--btn-hover-overlay\:theme\(colors\.white\/25\%\)\] {
  --btn-hover-overlay: #ffffff40;
}

.\[--btn-hover-overlay\:theme\(colors\.zinc\.950\/2\.5\%\)\] {
  --btn-hover-overlay: #09090b06;
}

.\[--btn-icon\:theme\(colors\.amber\.600\)\] {
  --btn-icon: #d97706;
}

.\[--btn-icon\:theme\(colors\.blue\.400\)\] {
  --btn-icon: #60a5fa;
}

.\[--btn-icon\:theme\(colors\.cyan\.500\)\] {
  --btn-icon: #06b6d4;
}

.\[--btn-icon\:theme\(colors\.fuchsia\.300\)\] {
  --btn-icon: #f0abfc;
}

.\[--btn-icon\:theme\(colors\.indigo\.300\)\] {
  --btn-icon: #a5b4fc;
}

.\[--btn-icon\:theme\(colors\.lime\.600\)\] {
  --btn-icon: #65a30d;
}

.\[--btn-icon\:theme\(colors\.orange\.300\)\] {
  --btn-icon: #fdba74;
}

.\[--btn-icon\:theme\(colors\.pink\.300\)\] {
  --btn-icon: #f9a8d4;
}

.\[--btn-icon\:theme\(colors\.purple\.300\)\] {
  --btn-icon: #d8b4fe;
}

.\[--btn-icon\:theme\(colors\.red\.300\)\] {
  --btn-icon: #fca5a5;
}

.\[--btn-icon\:theme\(colors\.rose\.300\)\] {
  --btn-icon: #fda4af;
}

.\[--btn-icon\:theme\(colors\.violet\.300\)\] {
  --btn-icon: #c4b5fd;
}

.\[--btn-icon\:theme\(colors\.white\/60\%\)\] {
  --btn-icon: #fff9;
}

.\[--btn-icon\:theme\(colors\.yellow\.600\)\] {
  --btn-icon: #ca8a04;
}

.\[--btn-icon\:theme\(colors\.zinc\.400\)\] {
  --btn-icon: #a1a1aa;
}

.\[--btn-icon\:theme\(colors\.zinc\.500\)\] {
  --btn-icon: #71717a;
}

.\[--gutter\:theme\(spacing\.8\)\] {
  --gutter: 2rem;
}

.\*\:w-full > * {
  width: 100%;
}

.placeholder\:text-gray-400::placeholder {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity, 1));
}

.placeholder\:text-zinc-500::placeholder {
  --tw-text-opacity: 1;
  color: rgb(113 113 122 / var(--tw-text-opacity, 1));
}

.before\:absolute:before {
  content: var(--tw-content);
  position: absolute;
}

.before\:inset-0:before {
  content: var(--tw-content);
  inset: 0;
}

.before\:inset-px:before {
  content: var(--tw-content);
  inset: 1px;
}

.before\:-z-10:before {
  content: var(--tw-content);
  z-index: -10;
}

.before\:rounded-\[calc\(theme\(borderRadius\.lg\)-1px\)\]:before {
  content: var(--tw-content);
  border-radius: calc(.5rem - 1px);
}

.before\:bg-\[--btn-bg\]:before {
  content: var(--tw-content);
  background-color: var(--btn-bg);
}

.before\:bg-white:before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.before\:shadow:before {
  content: var(--tw-content);
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.after\:pointer-events-none:after {
  content: var(--tw-content);
  pointer-events: none;
}

.after\:absolute:after {
  content: var(--tw-content);
  position: absolute;
}

.after\:inset-0:after {
  content: var(--tw-content);
  inset: 0;
}

.after\:-z-10:after {
  content: var(--tw-content);
  z-index: -10;
}

.after\:rounded-\[calc\(theme\(borderRadius\.lg\)-1px\)\]:after {
  content: var(--tw-content);
  border-radius: calc(.5rem - 1px);
}

.after\:rounded-lg:after {
  content: var(--tw-content);
  border-radius: .5rem;
}

.after\:shadow-\[shadow\:inset_0_1px_theme\(colors\.white\/15\%\)\]:after {
  content: var(--tw-content);
  --tw-shadow: inset 0 1px #ffffff26;
  --tw-shadow-colored: inset 0 1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.after\:ring-inset:after {
  content: var(--tw-content);
  --tw-ring-inset: inset;
}

.after\:ring-transparent:after {
  content: var(--tw-content);
  --tw-ring-color: transparent;
}

.hover\:bg-sky-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(53 151 194 / var(--tw-bg-opacity, 1));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:outline-0:focus {
  outline-width: 0;
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-inset:focus {
  --tw-ring-inset: inset;
}

.focus\:ring-sky-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(37 122 164 / var(--tw-ring-opacity, 1));
}

.focus-visible\:outline:focus-visible {
  outline-style: solid;
}

.focus-visible\:outline-2:focus-visible {
  outline-width: 2px;
}

.focus-visible\:outline-offset-2:focus-visible {
  outline-offset: 2px;
}

.focus-visible\:outline-sky-600:focus-visible {
  outline-color: #257aa4;
}

.has-\[\[data-disabled\]\]\:opacity-50:has([data-disabled]) {
  opacity: .5;
}

.before\:has-\[\[data-disabled\]\]\:bg-zinc-950\/5:has([data-disabled]):before {
  content: var(--tw-content);
  background-color: #09090b0d;
}

.before\:has-\[\[data-disabled\]\]\:shadow-none:has([data-disabled]):before {
  content: var(--tw-content);
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.before\:has-\[\[data-invalid\]\]\:shadow-red-500\/10:has([data-invalid]):before {
  content: var(--tw-content);
  --tw-shadow-color: #ef44441a;
  --tw-shadow: var(--tw-shadow-colored);
}

.data-\[disabled\]\:border-zinc-950\/20[data-disabled], .data-\[hover\]\:border-zinc-950\/20[data-hover] {
  border-color: #09090b33;
}

.data-\[invalid\]\:border-red-500[data-invalid], .data-\[invalid\]\:data-\[hover\]\:border-red-500[data-hover][data-invalid] {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity, 1));
}

.data-\[active\]\:bg-zinc-950\/5[data-active] {
  background-color: #09090b0d;
}

.data-\[active\]\:bg-zinc-950\/\[2\.5\%\][data-active] {
  background-color: #09090b06;
}

.data-\[hover\]\:bg-zinc-950\/5[data-hover] {
  background-color: #09090b0d;
}

.data-\[hover\]\:bg-zinc-950\/\[2\.5\%\][data-hover] {
  background-color: #09090b06;
}

.data-\[hover\]\:decoration-zinc-950[data-hover] {
  text-decoration-color: #09090b;
}

.data-\[disabled\]\:opacity-50[data-disabled] {
  opacity: .5;
}

.data-\[focus\]\:outline[data-focus] {
  outline-style: solid;
}

.data-\[focus\]\:outline-2[data-focus] {
  outline-width: 2px;
}

.data-\[focus\]\:outline-offset-2[data-focus] {
  outline-offset: 2px;
}

.data-\[focus\]\:outline-blue-500[data-focus] {
  outline-color: #3b82f6;
}

.data-\[active\]\:\[--btn-border\:theme\(colors\.zinc\.950\/15\%\)\][data-active], .data-\[hover\]\:\[--btn-border\:theme\(colors\.zinc\.950\/15\%\)\][data-hover] {
  --btn-border: #09090b26;
}

.data-\[active\]\:\[--btn-icon\:theme\(colors\.blue\.300\)\][data-active], .data-\[hover\]\:\[--btn-icon\:theme\(colors\.blue\.300\)\][data-hover] {
  --btn-icon: #93c5fd;
}

.data-\[active\]\:\[--btn-icon\:theme\(colors\.fuchsia\.200\)\][data-active], .data-\[hover\]\:\[--btn-icon\:theme\(colors\.fuchsia\.200\)\][data-hover] {
  --btn-icon: #f5d0fe;
}

.data-\[active\]\:\[--btn-icon\:theme\(colors\.indigo\.200\)\][data-active], .data-\[hover\]\:\[--btn-icon\:theme\(colors\.indigo\.200\)\][data-hover] {
  --btn-icon: #c7d2fe;
}

.data-\[active\]\:\[--btn-icon\:theme\(colors\.lime\.700\)\][data-active], .data-\[hover\]\:\[--btn-icon\:theme\(colors\.lime\.700\)\][data-hover] {
  --btn-icon: #4d7c0f;
}

.data-\[active\]\:\[--btn-icon\:theme\(colors\.orange\.200\)\][data-active], .data-\[hover\]\:\[--btn-icon\:theme\(colors\.orange\.200\)\][data-hover] {
  --btn-icon: #fed7aa;
}

.data-\[active\]\:\[--btn-icon\:theme\(colors\.pink\.200\)\][data-active], .data-\[hover\]\:\[--btn-icon\:theme\(colors\.pink\.200\)\][data-hover] {
  --btn-icon: #fbcfe8;
}

.data-\[active\]\:\[--btn-icon\:theme\(colors\.purple\.200\)\][data-active], .data-\[hover\]\:\[--btn-icon\:theme\(colors\.purple\.200\)\][data-hover] {
  --btn-icon: #e9d5ff;
}

.data-\[active\]\:\[--btn-icon\:theme\(colors\.red\.200\)\][data-active], .data-\[hover\]\:\[--btn-icon\:theme\(colors\.red\.200\)\][data-hover] {
  --btn-icon: #fecaca;
}

.data-\[active\]\:\[--btn-icon\:theme\(colors\.rose\.200\)\][data-active], .data-\[hover\]\:\[--btn-icon\:theme\(colors\.rose\.200\)\][data-hover] {
  --btn-icon: #fecdd3;
}

.data-\[active\]\:\[--btn-icon\:theme\(colors\.violet\.200\)\][data-active], .data-\[hover\]\:\[--btn-icon\:theme\(colors\.violet\.200\)\][data-hover] {
  --btn-icon: #ddd6fe;
}

.data-\[active\]\:\[--btn-icon\:theme\(colors\.white\/80\%\)\][data-active], .data-\[hover\]\:\[--btn-icon\:theme\(colors\.white\/80\%\)\][data-hover] {
  --btn-icon: #fffc;
}

.data-\[active\]\:\[--btn-icon\:theme\(colors\.yellow\.700\)\][data-active], .data-\[hover\]\:\[--btn-icon\:theme\(colors\.yellow\.700\)\][data-hover] {
  --btn-icon: #a16207;
}

.data-\[active\]\:\[--btn-icon\:theme\(colors\.zinc\.300\)\][data-active], .data-\[hover\]\:\[--btn-icon\:theme\(colors\.zinc\.300\)\][data-hover] {
  --btn-icon: #d4d4d8;
}

.data-\[active\]\:\[--btn-icon\:theme\(colors\.zinc\.500\)\][data-active], .data-\[hover\]\:\[--btn-icon\:theme\(colors\.zinc\.500\)\][data-hover] {
  --btn-icon: #71717a;
}

.data-\[active\]\:\[--btn-icon\:theme\(colors\.zinc\.700\)\][data-active], .data-\[hover\]\:\[--btn-icon\:theme\(colors\.zinc\.700\)\][data-hover] {
  --btn-icon: #3f3f46;
}

.before\:data-\[disabled\]\:shadow-none[data-disabled]:before {
  content: var(--tw-content);
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.after\:data-\[active\]\:bg-\[--btn-hover-overlay\][data-active]:after, .after\:data-\[hover\]\:bg-\[--btn-hover-overlay\][data-hover]:after {
  content: var(--tw-content);
  background-color: var(--btn-hover-overlay);
}

.after\:data-\[disabled\]\:shadow-none[data-disabled]:after {
  content: var(--tw-content);
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

@media (width >= 640px) {
  .sm\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .sm\:mb-auto {
    margin-bottom: auto;
  }

  .sm\:max-w-2xl {
    max-width: 42rem;
  }

  .sm\:max-w-3xl {
    max-width: 48rem;
  }

  .sm\:max-w-4xl {
    max-width: 56rem;
  }

  .sm\:max-w-5xl {
    max-width: 64rem;
  }

  .sm\:max-w-lg {
    max-width: 32rem;
  }

  .sm\:max-w-md {
    max-width: 28rem;
  }

  .sm\:max-w-sm {
    max-width: 24rem;
  }

  .sm\:max-w-xl {
    max-width: 36rem;
  }

  .sm\:max-w-xs {
    max-width: 20rem;
  }

  .sm\:translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:grid-rows-\[1fr_auto_3fr\] {
    grid-template-rows: 1fr auto 3fr;
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:rounded-2xl {
    border-radius: 1rem;
  }

  .sm\:p-4 {
    padding: 1rem;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:px-\[calc\(theme\(spacing\.3\)-1px\)\], .sm\:px-\[calc\(theme\(spacing\[3\]\)-1px\)\] {
    padding-left: calc(.75rem - 1px);
    padding-right: calc(.75rem - 1px);
  }

  .sm\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .sm\:py-\[calc\(theme\(spacing\[1\.5\]\)-1px\)\] {
    padding-top: calc(.375rem - 1px);
    padding-bottom: calc(.375rem - 1px);
  }

  .sm\:pl-6 {
    padding-left: 1.5rem;
  }

  .sm\:pt-0 {
    padding-top: 0;
  }

  .sm\:text-\[0\.8125rem\] {
    font-size: .8125rem;
  }

  .sm\:text-base\/6 {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .sm\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .sm\:text-sm\/6 {
    font-size: .875rem;
    line-height: 1.5rem;
  }

  .sm\:leading-6 {
    line-height: 1.5rem;
  }

  .sm\:\*\:w-auto > * {
    width: auto;
  }

  .sm\:after\:focus-within\:ring-2:focus-within:after {
    content: var(--tw-content);
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  }

  .sm\:after\:focus-within\:ring-blue-500:focus-within:after {
    content: var(--tw-content);
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(59 130 246 / var(--tw-ring-opacity, 1));
  }
}

@media (width >= 1024px) {
  .lg\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .lg\:block {
    display: block;
  }

  .lg\:w-96 {
    width: 24rem;
  }

  .lg\:flex-none {
    flex: none;
  }

  .lg\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .lg\:pl-8 {
    padding-left: 2rem;
  }
}

@media (width >= 1280px) {
  .xl\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }
}

@media (prefers-color-scheme: dark) {
  .dark\:border-white\/10 {
    border-color: #ffffff1a;
  }

  .dark\:border-white\/15 {
    border-color: #ffffff26;
  }

  .dark\:border-white\/20 {
    border-color: #fff3;
  }

  .dark\:border-white\/5 {
    border-color: #ffffff0d;
  }

  .dark\:bg-\[--btn-bg\] {
    background-color: var(--btn-bg);
  }

  .dark\:bg-white\/5 {
    background-color: #ffffff0d;
  }

  .dark\:bg-zinc-900 {
    --tw-bg-opacity: 1;
    background-color: rgb(24 24 27 / var(--tw-bg-opacity, 1));
  }

  .dark\:bg-zinc-950\/50 {
    background-color: #09090b80;
  }

  .dark\:text-red-500 {
    --tw-text-opacity: 1;
    color: rgb(239 68 68 / var(--tw-text-opacity, 1));
  }

  .dark\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity, 1));
  }

  .dark\:text-zinc-400 {
    --tw-text-opacity: 1;
    color: rgb(161 161 170 / var(--tw-text-opacity, 1));
  }

  .dark\:text-zinc-950 {
    --tw-text-opacity: 1;
    color: rgb(9 9 11 / var(--tw-text-opacity, 1));
  }

  .dark\:decoration-white\/50 {
    text-decoration-color: #ffffff80;
  }

  .dark\:ring-white\/10 {
    --tw-ring-color: #ffffff1a;
  }

  .dark\:\[--btn-bg\:theme\(colors\.zinc\.600\)\] {
    --btn-bg: #52525b;
  }

  .dark\:\[--btn-bg\:theme\(colors\.zinc\.800\)\] {
    --btn-bg: #27272a;
  }

  .dark\:\[--btn-bg\:transparent\] {
    --btn-bg: transparent;
  }

  .dark\:\[--btn-bg\:white\] {
    --btn-bg: white;
  }

  .dark\:\[--btn-hover-overlay\:theme\(colors\.white\/5\%\)\] {
    --btn-hover-overlay: #ffffff0d;
  }

  .dark\:\[--btn-hover-overlay\:theme\(colors\.zinc\.950\/5\%\)\] {
    --btn-hover-overlay: #09090b0d;
  }

  .dark\:\[--btn-icon\:theme\(colors\.zinc\.500\)\] {
    --btn-icon: #71717a;
  }

  .dark\:before\:hidden:before {
    content: var(--tw-content);
    display: none;
  }

  .dark\:after\:-inset-px:after {
    content: var(--tw-content);
    inset: -1px;
  }

  .dark\:after\:rounded-lg:after {
    content: var(--tw-content);
    border-radius: .5rem;
  }

  .dark\:data-\[hover\]\:border-white\/20[data-hover] {
    border-color: #fff3;
  }

  .dark\:data-\[hover\]\:data-\[disabled\]\:border-white\/15[data-disabled][data-hover], .data-\[disabled\]\:dark\:border-white\/15[data-disabled] {
    border-color: #ffffff26;
  }

  .data-\[invalid\]\:dark\:border-red-500[data-invalid], .data-\[invalid\]\:data-\[hover\]\:dark\:border-red-500[data-hover][data-invalid] {
    --tw-border-opacity: 1;
    border-color: rgb(239 68 68 / var(--tw-border-opacity, 1));
  }

  .dark\:data-\[active\]\:bg-white\/10[data-active] {
    background-color: #ffffff1a;
  }

  .dark\:data-\[active\]\:bg-white\/5[data-active] {
    background-color: #ffffff0d;
  }

  .dark\:data-\[hover\]\:bg-white\/10[data-hover] {
    background-color: #ffffff1a;
  }

  .dark\:data-\[hover\]\:bg-white\/5[data-hover] {
    background-color: #ffffff0d;
  }

  .data-\[disabled\]\:dark\:bg-white\/\[2\.5\%\][data-disabled] {
    background-color: #ffffff06;
  }

  .dark\:data-\[hover\]\:decoration-white[data-hover] {
    text-decoration-color: #fff;
  }

  .dark\:data-\[active\]\:\[--btn-icon\:theme\(colors\.zinc\.400\)\][data-active], .dark\:data-\[hover\]\:\[--btn-icon\:theme\(colors\.zinc\.400\)\][data-hover] {
    --btn-icon: #a1a1aa;
  }
}

@media (forced-colors: active) {
  .forced-colors\:outline {
    outline-style: solid;
  }

  .forced-colors\:\[--btn-icon\:ButtonText\], .forced-colors\:data-\[hover\]\:\[--btn-icon\:ButtonText\][data-hover] {
    --btn-icon: ButtonText;
  }
}

.\[\&\:\:-webkit-date-and-time-value\]\:min-h-\[1\.5em\]::-webkit-date-and-time-value {
  min-height: 1.5em;
}

.\[\&\:\:-webkit-datetime-edit-day-field\]\:p-0::-webkit-datetime-edit-day-field {
  padding: 0;
}

.\[\&\:\:-webkit-datetime-edit-fields-wrapper\]\:p-0::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

.\[\&\:\:-webkit-datetime-edit-hour-field\]\:p-0::-webkit-datetime-edit-hour-field {
  padding: 0;
}

.\[\&\:\:-webkit-datetime-edit-meridiem-field\]\:p-0::-webkit-datetime-edit-meridiem-field {
  padding: 0;
}

.\[\&\:\:-webkit-datetime-edit-millisecond-field\]\:p-0::-webkit-datetime-edit-millisecond-field {
  padding: 0;
}

.\[\&\:\:-webkit-datetime-edit-minute-field\]\:p-0::-webkit-datetime-edit-minute-field {
  padding: 0;
}

.\[\&\:\:-webkit-datetime-edit-month-field\]\:p-0::-webkit-datetime-edit-month-field {
  padding: 0;
}

.\[\&\:\:-webkit-datetime-edit-second-field\]\:p-0::-webkit-datetime-edit-second-field {
  padding: 0;
}

.\[\&\:\:-webkit-datetime-edit-year-field\]\:p-0::-webkit-datetime-edit-year-field {
  padding: 0;
}

.\[\&\:\:-webkit-datetime-edit\]\:inline-flex::-webkit-datetime-edit {
  display: inline-flex;
}

.\[\&\:\:-webkit-datetime-edit\]\:p-0::-webkit-datetime-edit {
  padding: 0;
}

.\[\&\>\*\+\[data-slot\=control\]\]\:mt-6 > * + [data-slot="control"] {
  margin-top: 1.5rem;
}

.\[\&\>\[data-slot\=control\]\+\[data-slot\=description\]\]\:mt-3 > [data-slot="control"] + [data-slot="description"], .\[\&\>\[data-slot\=control\]\+\[data-slot\=error\]\]\:mt-3 > [data-slot="control"] + [data-slot="error"], .\[\&\>\[data-slot\=description\]\+\[data-slot\=control\]\]\:mt-3 > [data-slot="description"] + [data-slot="control"] {
  margin-top: .75rem;
}

.\[\&\>\[data-slot\=icon\]\]\:-mx-0\.5 > [data-slot="icon"] {
  margin-left: -.125rem;
  margin-right: -.125rem;
}

.\[\&\>\[data-slot\=icon\]\]\:my-0\.5 > [data-slot="icon"] {
  margin-top: .125rem;
  margin-bottom: .125rem;
}

.\[\&\>\[data-slot\=icon\]\]\:size-5 > [data-slot="icon"] {
  width: 1.25rem;
  height: 1.25rem;
}

.\[\&\>\[data-slot\=icon\]\]\:shrink-0 > [data-slot="icon"] {
  flex-shrink: 0;
}

.\[\&\>\[data-slot\=icon\]\]\:text-\[--btn-icon\] > [data-slot="icon"] {
  color: var(--btn-icon);
}

@media (width >= 640px) {
  .\[\&\>\[data-slot\=icon\]\]\:sm\:my-1 > [data-slot="icon"] {
    margin-top: .25rem;
    margin-bottom: .25rem;
  }

  .\[\&\>\[data-slot\=icon\]\]\:sm\:size-4 > [data-slot="icon"] {
    width: 1rem;
    height: 1rem;
  }
}

.\[\&\>\[data-slot\=label\]\+\[data-slot\=control\]\]\:mt-3 > [data-slot="label"] + [data-slot="control"] {
  margin-top: .75rem;
}

.\[\&\>\[data-slot\=label\]\+\[data-slot\=description\]\]\:mt-1 > [data-slot="label"] + [data-slot="description"] {
  margin-top: .25rem;
}

.\[\&\>\[data-slot\=label\]\]\:font-medium > [data-slot="label"] {
  font-weight: 500;
}

.\[\&\>\[data-slot\=text\]\]\:mt-1 > [data-slot="text"] {
  margin-top: .25rem;
}

@media (pointer: fine) {
  .\[\@media\(pointer\:fine\)\]\:hidden {
    display: none;
  }
}
/*# sourceMappingURL=index.348616bd.css.map */
